import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";

let galleryData = [];
let attr = [];
let trait = [];
let value = [];
let loginWindow = 0;
let imgURL;
let edition;

class Gallery extends Component {

    state = {
        _galleryData: [],
        _galleryShow: [],
        _loginWindow: 0,
        _imgURL: '',
        _attr: '',
        _edition: '',
        _ID: '',
        _images: 0
    }

    async goToOpensea(id) {
        var picID = id-1;

        window.open("https://opensea.io/assets/ethereum/0x8626c56c9225d7b19f624dd20aa6a745279ab842/" + picID);


    }


    galleryOpen = async event => {
        event.preventDefault();

        let galleryShow = [];

        for (let i = 1; i <= 4455; i++) {

            galleryShow[i - 1] = i;
        }
        this.setState({ _galleryShow: galleryShow });
        this.setState({_images:1});

    }

    closeWindow = async event => {
		event.preventDefault();

    this.setState({_images:0});
    }

    render() {
        return (

            <div class="boxWrap5">

                <button class="button-85" onClick={this.galleryOpen} role="button">Quantum Beavers Gallery</button>

                {this.state._images > 0 ?

                (<div class="formMain1">
                    <div class="formMain">
                        <div class="galleryPicsMain">
                            <div class="galleryPics">

                                {this.state._galleryShow.map((gallery) => {
                                    let imageLink = "https://quantumbeavers.com/images/" + gallery + ".png";
                                    console.log("imageLink :" + imageLink);
                                    return (
                                        <div class="imgName">
                                            <div class="imgNameDiv">Quantum Beavers #{gallery}</div>
                                            <img class="images_show" src={imageLink} onClick={()=> this.goToOpensea(gallery)} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                   
                    </div>
                    <form onSubmit={this.closeWindow}>
                            <button class="closeNote">✖</button>
                        </form>
                </div>
                ):null
                }
            </div>
        )
    }
}

export default Gallery;

